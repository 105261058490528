import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'App';
import { mockServer } from 'utils/server-mock';
import './assets/fonts/fonts.css';
import './styles/base.css';

import { initErrorTracking } from './errorTracking';

/*
 * This file is our app main entry
 * all js helper and services can load from here
 * */
initErrorTracking();
if (import.meta.env.REACT_APP_MOCK_SERVER === 'true') {
  mockServer();
}

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
