import { isNil } from 'lodash';
import React, { useState } from 'react';

import { Alert, Text } from '@summer/ui-components';

import { getFaxSubmissionStatus, getFormFaxErrorMessage } from 'utils/fax';
import ConfirmationModalButton from 'views/Form/AutoFax/ConfirmationModalButton';

const AutoFax = ({
  formId,
  formType,
  formStatus,
  faxSubmission = null,
  formIsConsolidation,
  formServicersToSendIDR,
}) => {
  const formFaxErrorMessage = getFormFaxErrorMessage(
    formType,
    formIsConsolidation,
    formServicersToSendIDR,
    formStatus,
  );
  const [newFaxSubmission, setNewFaxSubmission] = useState(null);
  const getNewestFaxSubmission = () => {
    return newFaxSubmission || faxSubmission;
  };
  const faxStatus = getFaxSubmissionStatus(getNewestFaxSubmission());

  const faxHasErrorStatus = faxStatus?.type === 'warning';
  const showOpenModalButton = faxStatus === null || faxHasErrorStatus;
  const disableOpenModalButton = !isNil(formFaxErrorMessage);

  return (
    <>
      <Text bold>Fax</Text>
      {showOpenModalButton && (
        <>
          <ConfirmationModalButton
            formId={formId}
            formType={formType}
            formStatus={formStatus}
            disableOpenModalButton={disableOpenModalButton}
            faxNeedsRetry={faxHasErrorStatus}
            setNewFaxSubmission={setNewFaxSubmission}
          />
          {formFaxErrorMessage && (
            <Text paragraph small>
              {formFaxErrorMessage}
            </Text>
          )}
        </>
      )}

      {!isNil(faxStatus) && (
        <Alert theme={faxStatus.type} dismissable={false}>
          {faxStatus.alertText}
        </Alert>
      )}
    </>
  );
};

export default AutoFax;
