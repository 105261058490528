import { init, configureScope } from '@sentry/react';

const DSN = import.meta.env.REACT_APP_SENTRY_DSN;
const ENV = import.meta.env.REACT_APP_NODE_ENV || import.meta.env.NODE_ENV;

export const initErrorTracking = () => {
  if (ENV !== 'development') {
    init({
      dsn: DSN,
      environment: ENV,
    });
  }
};

export const setAdminUserForErrorTracking = ({ email, id }) => {
  configureScope(scope => {
    scope.setUser({ email, id });
  });
};
