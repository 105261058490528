import { format, parseISO } from 'date-fns';
import { startCase } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  ArrowUp,
  Collapse,
  DataDisplay,
  FlexRow,
  Header,
  Text,
  Icon,
} from '@summer/ui-components';

import { Box } from 'components/box/Box';
import DownloadFile from 'components/buttons/DownloadFile';
import NoDataToDisplay from 'components/placeholders/NoDataToDisplay';
import { LOAN_TYPE_FSA } from 'utils/constants';

import { useBorrower } from '../../state/server/borrowers';

const Download = () => {
  const { data: borrower, isSuccess } = useBorrower(useParams().borrowerId);
  if (!isSuccess) {
    return null;
  }

  const { id, loans } = borrower;
  if (typeof id !== 'number') {
    return null;
  }

  const href = `${
    import.meta.env.REACT_APP_API_BASE_URL || ''
  }/v1/borrowers/${id}/nslds`;
  return (
    <DownloadFile
      disabled={!Array.isArray(loans) || loans.length === 0}
      href={href}
      displayName="Download NSLDS"
    />
  );
};

const BorrowerLoanSummary = ({ borrowerInfo, type }) => {
  const { data: borrower } = useBorrower(useParams().borrowerId);
  const { latestFsaFileUploadDate } = borrower;

  return (
    <Box mt={40}>
      <Collapse
        openByDefault={true}
        trigger={({ openState }) => (
          <CollapseTrigger>
            <Header size="large" weight="bold">
              Loan Summary (From {startCase(type)} Sync)
            </Header>
            <Arrow fill="#6D7581" open={openState} SvgComponent={ArrowUp} />
            {type === LOAN_TYPE_FSA && latestFsaFileUploadDate && (
              <FsaFileUploadDateContainer size="small">
                Last FSA File Upload Date:{' '}
                {format(
                  parseISO(latestFsaFileUploadDate),
                  'LLLL dd, yyyy h:mm a',
                )}
              </FsaFileUploadDateContainer>
            )}
          </CollapseTrigger>
        )}
      >
        <Box
          bg="white"
          border="solid 1px"
          borderRadius={6}
          borderColor="blueGrey"
          px={24}
          pt={24}
          mt={24}
          style={{ position: 'relative' }}
        >
          {!borrowerInfo ? (
            <Container height={137} mt={24}>
              <NoDataToDisplay />
            </Container>
          ) : (
            <>
              {type === LOAN_TYPE_FSA && (
                <DownloadContainer>
                  <Download />
                </DownloadContainer>
              )}
              <DataDisplay data={borrowerInfo} />
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
const Container = styled(Box)`
  max-height: 137px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  div {
    max-height: 137px;
  }
`;
const CollapseTrigger = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  padding: 16px 0;
  margin-bottom: 24px;
`;

const Arrow = styled(Icon)`
  cursor: pointer;
  margin-left: 8px;
  transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-out;
`;

const FsaFileUploadDateContainer = styled(Text)`
  margin-left: 16px;
  align-self: center;
`;

/*
 * to be honest this is atrocious, but if someone has a better way to
 * position it relative to the title of the DataDisplay component,
 * please do.
 */
const DownloadContainer = styled.div`
  position: absolute;
  left: 125px;
`;

export default BorrowerLoanSummary;
