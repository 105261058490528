import { faker } from '@faker-js/faker';
import { filter, omitBy, isNil, map, has } from 'lodash';
import { Server, Model, Factory } from 'miragejs';

const servicers = [
  'Debt Management and Collections System',
  'FedLoan Servicing (PHEAA)',
  'Granite State - GSMR',
  'Great Lakes',
  'MOHELA',
  'Nelnet',
  'OSLA Servicing',
  'AES',
  'Navient',
  'CornerStone',
  'University / Other',
  'FSA',
  'MyEdDebt',
  'Edfinancial/HESC',
  'AidVantage',
];
const appTypes = ['new', 'switch', 'recalculate', 'recertify'];
const pathTypes = ['ICR', 'IBR', 'PAYE', 'REPAYE', 'STANDARD'];
const formTypes = ['IDR', 'PSLF'];
const employerTypes = [
  '501c3 non-profit',
  'non-501c3 non-profit',
  'government org',
  'none of the above',
];
const statusesData = [
  { status: 'Signed by Borrower', formType: 'both' },
  { status: 'Signed by Employer', formType: 'pslf' },
  { status: 'Ready to Process', formType: 'both' },
  { status: 'In Progress', formType: 'both' },
  { status: 'Pending Summer', formType: 'both' },
  { status: 'Pending Borrower', formType: 'both' },
  { status: 'Pending IDR/Consolidation Form', formType: 'pslf' },
  { status: 'Will Not Submit', formType: 'both' },
  { status: 'Submitted to Servicer', formType: 'both' },
  { status: 'Accepted by Servicer', formType: 'both' },
  { status: 'Rejected by Servicer', formType: 'both' },
];
const adminUserData = [
  {
    uuid: faker.string.uuid(),
    name: faker.person.fullName(),
    profilePictureUrl: faker.image.url(),
  },
  {
    uuid: faker.string.uuid(),
    name: faker.person.fullName(),
    profilePictureUrl: faker.image.url(),
  },
  {
    uuid: faker.string.uuid(),
    name: faker.person.fullName(),
    profilePictureUrl: faker.image.url(),
  },
];
const createPslfForm = () => ({
  employerName: faker.company.name(),
  employerType: employerTypes[faker.number.int(employerTypes.length - 1)],
  employerStartDate: faker.date.past(),
  employerEndDate: faker.date.future(),
  employerHoursPerWeek: faker.number.int(100),
  hrEmail: faker.internet.email(),
  hrFirstName: faker.person.firstName(),
  hrLastName: faker.person.lastName(),
});
const createIDRForm = () => {
  const isConsolidation = faker.datatype.boolean();
  return {
    isConsolidation: faker.datatype.boolean(),
    currentPath: {
      type: [pathTypes[faker.number.int(pathTypes.length - 1)]],
      appType: appTypes[faker.number.int(appTypes.length - 1)],
    },
    selectedPath: {
      type: [pathTypes[faker.number.int(pathTypes.length - 1)]],
      appType: appTypes[faker.number.int(appTypes.length - 1)],
    },
    servicersToSendIDR: map(
      Array(faker.number.int({ min: 1, max: 2 })),
      () => servicers[faker.number.int(servicers.length - 1)],
    ),
    targetServicerName: isConsolidation
      ? servicers[faker.number.int(servicers.length - 1)]
      : null,
  };
};

const formsFactory = Factory.extend({
  // factory properties go here
  status: null,
  formId(i) {
    faker.seed(i + 1);
    return faker.string.uuid();
  },
  formType(i) {
    faker.seed(i + 1);
    return formTypes[faker.number.int(formTypes.length - 1)];
  },
  createdAt(i) {
    faker.seed(i + 1);
    return faker.date.past();
  },
  borrowerId(i) {
    faker.seed(i + 1);
    return faker.number.int();
  },
  firstName(i) {
    faker.seed(i + 1);
    return faker.person.firstName();
  },
  lastName(i) {
    faker.seed(i + 1);
    return faker.person.lastName();
  },
  updatedAt(i) {
    faker.seed(i + 1);
    return faker.date.future();
  },
  metadata(i) {
    faker.seed(i + 1);
    const adminUserIndex = faker.number.int(adminUserData.length - 1);
    const statusesDataIndex = faker.number.int(statusesData.length - 1);
    const metaData = () => ({
      owner: {
        uuid: adminUserData[adminUserIndex].uuid,
        name: adminUserData[adminUserIndex].name,
      },
      status: statusesData[statusesDataIndex].status,
      dateSubmitted: faker.date.past(),
    });
    return metaData();
  },
});
const formFactory = Factory.extend({
  id(i) {
    faker.seed(i + 1);
    return faker.string.uuid();
  },
  formType(i) {
    faker.seed(i + 1);
    return formTypes[faker.number.int(formTypes.length - 1)];
  },
  status(i) {
    faker.seed(i + 1);
    return null;
  },
  createdAt(i) {
    faker.seed(i + 1);
    return faker.date.past();
  },
  updatedAt(i) {
    faker.seed(i + 1);
    return faker.date.future();
  },
  // see the GetUser route for these
  form() {
    if (this.formType === 'IDR') {
      return createIDRForm();
    }
    if (this.formType === 'PSLF') {
      return createPslfForm();
    }
    return null;
  },
  signRequests() {
    return null;
  },
  borrower(i) {
    faker.seed(i + 1);
    const id = faker.number.int();
    faker.seed(i + 1);
    const firstName = faker.person.firstName();
    faker.seed(i + 1);
    const lastName = faker.person.lastName();
    faker.seed(i + 1);
    return {
      id,
      firstName,
      lastName,
      email: faker.internet.email(),
    };
  },
  metadata() {
    return {
      owner: {
        uuid: null,
        name: null,
      },
      status: null,
      dateSubmitted: null,
      formInZendesk: null,
      notes: null,
    };
  },
});

export const mockServer = () => {
  return new Server({
    models: {
      formBase: Model,
      form: Model,
    },

    factories: {
      formBase: formsFactory,
      form: formFactory,
    },

    seeds(server) {
      server.createList('formBase', 10);
      server.createList('form', 10);
    },

    routes() {
      this.urlPrefix = import.meta.env.REACT_APP_API_BASE_URL;
      this.namespace = '/v1';

      this.get('/statuses', () => {
        return { data: statusesData };
      });
      this.get('/admin-users', () => {
        return adminUserData;
      });

      this.get('/forms', (schema, request) => {
        const {
          user_id: borrowerId,
          owner_uuid: ownerUuid,
          status,
        } = request.queryParams;

        const metadata = omitBy(
          {
            owner: ownerUuid && { uuid: ownerUuid },
            status,
          },
          isNil,
        );

        const filters = omitBy(
          {
            userId: borrowerId && Number(borrowerId),
            metadata: (ownerUuid || status) && metadata,
          },
          isNil,
        );
        return { data: filter(schema.db.formBases, filters) };
      });

      this.get('/forms/:id', (schema, request) => {
        const { id } = request.params;
        return { data: schema.db.forms.find(id) };
      });
      this.put('/forms/:id/metadata', (schema, request) => {
        const { id } = request.params;
        const form = schema.forms.find(id);
        const requestMetadata = JSON.parse(request.requestBody);
        const formMetadata = form.metadata;

        let owner = {};
        if (has(requestMetadata, 'owner')) {
          owner = { ...formMetadata.owner, uuid: requestMetadata?.owner };
        } else {
          owner = formMetadata.owner;
        }

        let dateSubmitted = null;
        if (has(requestMetadata, 'status')) {
          dateSubmitted = new Date();
        } else {
          dateSubmitted = formMetadata.dateSubmitted;
        }

        const newMetadata = {
          ...formMetadata,
          ...requestMetadata,
          owner,
          dateSubmitted,
        };

        form.update({
          metadata: newMetadata,
        });
        return { data: newMetadata };
      });

      // Bypass mock server for oauth
      const host = import.meta.env.REACT_APP_API_BASE_URL;
      this.passthrough(`${host}/v1/oauth/**`);
      this.passthrough(request => {
        try {
          // if request.url is not valid url we will not passthrough (help if REACT_APP_API_BASE_URL not set)
          const origin = new URL(request.url)?.origin;
          return host !== origin;
        } catch (e) {
          return false;
        }
      });
    },
  });
};
