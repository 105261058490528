import React, { useState, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Button, Header, Text } from '@summer/ui-components';

import { useMutateSubmitFax } from 'state/server/fax';
import {
  PAGE_LIMIT_ERROR,
  FILE_SIZE_ERROR,
  defaultServerErrorDisplayMessage,
  pageLimitErrorMessage,
  fileSizeErrorMessage,
} from 'utils/fax';

const ConfirmationModal = ({
  formId,
  setIsModalPopupOpen,
  setNewFaxSubmission,
}) => {
  const queryClient = useQueryClient();
  const [serverError, setServerError] = useState(null);

  const serverErrorDisplayMessage = useMemo(() => {
    if (!serverError) {
      return null;
    }
    if (serverError === PAGE_LIMIT_ERROR) {
      return pageLimitErrorMessage;
    }
    if (serverError === FILE_SIZE_ERROR) {
      return fileSizeErrorMessage;
    }
    return defaultServerErrorDisplayMessage;
  }, [serverError]);

  const testFaxNumber = new URLSearchParams(window.location.search).get(
    'testFaxNumber',
  );

  const { mutate, isLoading } = useMutateSubmitFax({
    formId,
  });

  const submitFax = () => {
    mutate(
      { formId, testFaxNumber },
      {
        onSuccess: data => {
          setNewFaxSubmission(data);
          queryClient.invalidateQueries(['form', `${formId}`]);
        },
        onError: error => {
          setServerError(
            error.response?.data?.message || 'An unknown server error occurred',
          );
        },
      },
    );
  };

  return (
    <>
      <Header as="h1">Are you sure you want to fax these forms?</Header>
      <ButtonContainer>
        <Button
          secondary
          borderless
          width={144}
          onClick={() => setIsModalPopupOpen(false)}
        >
          No, cancel
        </Button>{' '}
        <Button
          width={232}
          disabled={!!serverError}
          isLoading={isLoading}
          onClick={submitFax}
        >
          Yes, fax these forms
        </Button>
      </ButtonContainer>
      {serverError && (
        <ErrorTextContainer>
          <Text color="error">{serverErrorDisplayMessage}</Text>
        </ErrorTextContainer>
      )}
    </>
  );
};

const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
`;

const ErrorTextContainer = styled.div`
  margin-top: 16px;
`;

export default ConfirmationModal;
